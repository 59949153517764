var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"edit-scheduled-email",attrs:{"id":"edit-scheduled-email","centered":"","title":"Edit Scheduled Email","no-close-on-backdrop":"","no-enforce-focus":"","size":"lg"},on:{"ok":_vm.handleForm,"show":_vm.setModalData,"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('div',{staticClass:"mt-50 d-flex justify-content-end align-items-center"},[(_vm.communication.audienceType == 'group')?_c('span',{staticClass:"mr-1 text-muted"},[_vm._v(_vm._s(_vm.communication.group ? _vm.communication.group.member_count : 0)+" Contacts")]):_vm._e(),_vm._v(" "),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return ok()}}},[_vm._v(" Save ")])],1)]}}])},[_c('validation-observer',{ref:"scheduledEmailRules"},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.isLoading,"variant":"white","opacity":"1","rounded":"sm"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Audience Type"}},[_c('validation-provider',{attrs:{"name":"Audience Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allAudienceTypesList,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.communication.audienceType),callback:function ($$v) {_vm.$set(_vm.communication, "audienceType", $$v)},expression:"communication.audienceType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.communication.audienceType == 'group')?_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Group"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allGroupsList,"clearable":false,"label":"name","placeholder":_vm.fieldGroupStatus},model:{value:(_vm.communication.group),callback:function ($$v) {_vm.$set(_vm.communication, "group", $$v)},expression:"communication.group"}})],1)],1):_vm._e(),(_vm.communication.audienceType == 'report')?_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Report"}},[_c('validation-provider',{attrs:{"name":"Report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allReportsList,"clearable":false,"label":"name","placeholder":_vm.fieldReportStatus},on:{"input":_vm.updateReportFields},model:{value:(_vm.communication.report),callback:function ($$v) {_vm.$set(_vm.communication, "report", $$v)},expression:"communication.report"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2517622302)})],1)],1):_vm._e(),(_vm.communication.audienceType == 'report')?_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Report field"}},[_c('validation-provider',{attrs:{"name":"Report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allReportFieldsList,"reduce":function (option) { return option.value; },"clearable":false,"disabled":!_vm.communication.report,"placeholder":"Select field"},model:{value:(_vm.communication.reportField),callback:function ($$v) {_vm.$set(_vm.communication, "reportField", $$v)},expression:"communication.reportField"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2020117791)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Communication Type"}},[_c('validation-provider',{attrs:{"name":"communication type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.communicationTypeOptions,"clearable":false,"label":"name","value":"id","reduce":function (type) { return type.id; },"selectable":function (type) { return type.active; },"placeholder":_vm.fieldTypeStatus},model:{value:(_vm.communication.communicationType),callback:function ($$v) {_vm.$set(_vm.communication, "communicationType", $$v)},expression:"communication.communicationType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Email Template"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allEmailTemplatesList,"clearable":false,"label":"name","value":"id","placeholder":_vm.fieldTemplateStatus},on:{"input":_vm.selectedEmailTemplate},model:{value:(_vm.communication.emailTemplate),callback:function ($$v) {_vm.$set(_vm.communication, "emailTemplate", $$v)},expression:"communication.emailTemplate"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"id":"subject","label":"Subject"}},[_c('validation-provider',{attrs:{"name":"subject","rules":"required","vid":"subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"emailSubject","name":"subject","placeholder":"Subject"},model:{value:(_vm.communication.subject),callback:function ($$v) {_vm.$set(_vm.communication, "subject", $$v)},expression:"communication.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Letter Template"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allLetterTemplatesList,"label":"name","reduce":function (option) { return option.id; },"placeholder":_vm.fieldLetterTemplateStatus},model:{value:(_vm.communication.letterTemplate),callback:function ($$v) {_vm.$set(_vm.communication, "letterTemplate", $$v)},expression:"communication.letterTemplate"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"From Name"}},[_c('b-form-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.communication.fromName),callback:function ($$v) {_vm.$set(_vm.communication, "fromName", $$v)},expression:"communication.fromName"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"id":"email","label":"From Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fromEmail","name":"email","placeholder":"Email"},model:{value:(_vm.communication.fromEmail),callback:function ($$v) {_vm.$set(_vm.communication, "fromEmail", $$v)},expression:"communication.fromEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"User Role"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allUserRoles,"disabled":_vm.communication.audienceType !== 'group',"reduce":function (role) { return role.value; },"clearable":false},model:{value:(_vm.communication.role),callback:function ($$v) {_vm.$set(_vm.communication, "role", $$v)},expression:"communication.role"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Scheduler"}},[_c('div',{staticStyle:{"marginTop":"10px"}},[_c('b-form-radio',{attrs:{"name":"scheduler","value":"now","inline":""},model:{value:(_vm.communication.schedule),callback:function ($$v) {_vm.$set(_vm.communication, "schedule", $$v)},expression:"communication.schedule"}},[_vm._v(" Now ")]),_c('b-form-radio',{staticClass:"ml-2",attrs:{"name":"scheduler","value":"later","inline":""},model:{value:(_vm.communication.schedule),callback:function ($$v) {_vm.$set(_vm.communication, "schedule", $$v)},expression:"communication.schedule"}},[_vm._v(" Later ")])],1)])],1),(_vm.communication.schedule == 'later')?_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Send at"}},[_c('validation-provider',{attrs:{"name":"send at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickerDateTimeConfig,"placeholder":'Select Date/Time'},model:{value:(_vm.communication.sendAt),callback:function ($$v) {_vm.$set(_vm.communication, "sendAt", $$v)},expression:"communication.sendAt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3356451686)})],1)],1):_vm._e(),(_vm.communication.schedule == 'later')?_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Recurring"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allRecurringTypesList,"reduce":function (option) { return option.value; },"placeholder":"Select"},model:{value:(_vm.communication.recurring),callback:function ($$v) {_vm.$set(_vm.communication, "recurring", $$v)},expression:"communication.recurring"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Body","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{ref:"myEditor",attrs:{"id":"email-content","api-key":_vm.tinyAPI,"init":_vm.tinyOptions},model:{value:(_vm.communication.content),callback:function ($$v) {_vm.$set(_vm.communication, "content", $$v)},expression:"communication.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('AvailablePlaceholder',{on:{"insertValueToEditor":_vm.insertValueToEditor}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }